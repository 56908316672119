<template>
  <div>404 Not Found</div>
</template>

<style lang="scss">
</style>

<script>
  import http from '@/@libs/http';
  import bus from '@/@libs/bus';

  export default {
    name: 'NotFound',
    components: {
    },
    props: {},
    data() {
      return {
      };
    },
    created() {
    },
    computed: {
    },
    methods: {
    },
  };
</script>
