let events = [];

function on(eventName, callback) {
  events = events.concat({
    eventName,
    callback,
  });
}

function once(eventName, callback) {
  events = events.concat({
    eventName,
    callback,
    once: true,
  });
}

function off(eventName, callback) {
  if (!eventName || !callback) {
    return;
  }
  events = events.filter((event) => {
    if (callback && event.callback !== callback) {
      return true;
    }
    if (eventName && eventName !== event.eventName) {
      return true;
    }
    return false;
  });
}

function trigger(eventName, payload) {
  events = events.filter((event) => {
    if (event.eventName === eventName) {
      event.callback(payload);
      if (event.once) {
        return false;
      }
    }
    return true;
  });
}

const bus = {
  on,
  once,
  off,
  trigger,
};

if (typeof window !== 'undefined') {
  window.bus = bus;
}

export default bus;
