<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog
    v-model="showConfirm"
    :max-width="maxWidth"
    @keydown.enter.prevent="onSelect(true)"
  >
    <v-card>
      <v-card-title>{{title}}</v-card-title>

      <v-card-text>
        <span v-html="text"></span>
      </v-card-text>

      <v-card-actions>
        <div class="flex-grow-1"></div>

        <v-btn
          ref="selectButton"
          @click="onSelect(false)"
        >
          {{cancelText}}
        </v-btn>

        <v-btn
          :color="confirmColor"
          @click="onSelect(true)"
        >
          {{confirmText}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
</style>

<script>
  import bus from '@/@libs/bus';

  export default {
    name: 'Confirm',
    components: {
    },
    props: {
    },
    data() {
      return {
        title: '',
        text: '',
        confirmText: '',
        cancelText: '',
        confirmColor: 'primary',
        onSelectCallback: null,
        showConfirm: false,
        maxWidth: 290,
      };
    },
    mounted() {
      bus.on('confirm', this.openConfirm);
    },
    beforeDestroy() {
      bus.off('confirm', this.openConfirm);
    },
    computed: {
    },
    methods: {
      onSelect(response) {
        if (this.onSelectCallback) {
          this.onSelectCallback(response);
        }
        this.onSelectCallback = null;
        this.title = '';
        this.text = '';
        this.showConfirm = false;
      },
      openConfirm({ title, text, onSelect, confirmText = 'Yes', cancelText = 'No', maxWidth = 290, confirmColor = 'primary' }) {
        if (this.onSelectCallback) {
          // if we have an old unresolved confirm resolve it with null
          this.onSelectCallback(null);
        }
        this.showConfirm = true;
        // we need to wait for btn to come into the dom first
        // setTimeout(() => {
        //   this.$refs.selectButton.$el.focus();
        // }, 100);
        this.title = title;
        this.text = text;
        this.confirmText = confirmText;
        this.confirmColor = confirmColor;
        this.cancelText = cancelText;
        this.onSelectCallback = onSelect;
        this.maxWidth = maxWidth;
      },
    },
  };
</script>
