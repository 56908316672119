function createError(name, errorCode, defaultMessage) {
  const customError = function (message) {
    this.name = name;
    this.message = message || defaultMessage;
    this.stack = (new Error(message || defaultMessage)).stack;
    this.errorCode = errorCode;
    this.code = errorCode;
    // looks like uncommenting the following shows the proper error but wrong stack instead
    // if (Error.captureStackTrace) {
    //   // for v8 this is preferable:
    //   Error.captureStackTrace(this, customError);
    // }
  };
  customError.prototype = Object.create(Error.prototype);
  customError.prototype.constructor = customError;

  return customError;
}

// HTTP errors
export const HttpNotFoundError = createError(
  'HttpNotFoundError',
  'HTTP_NOT_FOUND',
  '404 Not Found',
);
export const HttpNotAuthorizedError = createError(
  'HttpNotAuthorizedError',
  'HTTP_NOT_AUTHORIZED',
  '401 Not Authorized',
);
export const HttpForbiddenError = createError(
  'HttpForbiddenError',
  'HTTP_FORBIDDEN',
  '403 Forbidden',
);
export const HttpServerError = createError(
  'httpServerError',
  'HTTP_SERVER_ERROR',
  '500 Internal Server Error',
);
export const HttpBadRequestError = createError(
  'httpBadRequestError',
  'HTTP_BAD_REQUEST',
  '500 Bad Request',
);

// Auth Errors
export const NotAuthorizedError = createError(
  'NotAuthorizedError',
  'NOT_AUTHORIZED',
  'Not Authorized',
);
export const ForbiddenError = createError(
  'ForbiddenError',
  'FORBIDDEN_ERROR',
  'Forbidden',
);
