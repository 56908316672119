import Vue from 'vue';
import App from './App';
import router from './router';
import store from './@state';
import './plugins/v-mask';
import './@filters/filters';
import './@components';
import bus from './@libs/bus';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;

Vue.config.errorHandler = function (_error, vm, info) {
  const error = _error || new Error('Unknown Error: Vue errorHandler');
  // handle error
  // `info` is a Vue-specific error info, e.g. which lifecycle hook
  // the error was found in. Only available in 2.2.0+
  console.error(error);
  bus.trigger('error', {
    error,
  });
};


window.onerror = function (message, source, line, column, _error) {
  const error = _error || new Error('Unknown Error: onError listener');
  console.error(error);
  bus.trigger('error', {
    error,
  });
};

window.addEventListener('unhandledrejection', (promiseRejectionEvent) => {
  promiseRejectionEvent.promise.catch((_error) => {
    const error = _error || new Error('Unknown Error: unhandledrejection listener');
    console.error(error);
    bus.trigger('error', {
      error,
    });
  });
  // handle error here, for example log
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
