<template>
  <div class="header-wrapper">
    <div data-collapse="medium" data-animation="default" data-duration="400" data-easing="ease" data-easing2="ease" data-doc-height="1" role="banner" class="navigation w-nav">
      <div class="navigation-container">
        <router-link to="/" class="logo-link w-inline-block w--current">
          <img src="/images/goodcards-logo-h.svg" loading="lazy" width="124" alt="" class="logo">
        </router-link>
        <div class="navigation-wrapper">
<!--          <div class="menu-button w-nav-button">
            <div class="menu-icon w-embed">
              <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M5.125 5.88892H18.875V7.41669H5.125V5.88892ZM5.125 11.2361H18.875V12.7639H5.125V11.2361ZM5.125 16.5834H18.875V18.1111H5.125V16.5834Z"
                  fill="currentColor"
                ></path>
              </svg>
            </div>
          </div>-->
<!--          <nav role="navigation" class="navigation-menu w-nav-menu">
            <a href="#" class="navigation-link w-nav-link">About GoodCards</a>
            <a href="#" class="navigation-link w-nav-link">Buy</a>
            <a href="redeem/step1" class="navigation-link mobile w-nav-link">Redeem</a>
          </nav>-->
<!--          <a href="redeem/step1" class="navigation-button w-inline-block">
            <div>Redeem</div>
          </a>-->
          <v-btn
            depressed
            color="primary"
            outlined
            class="pa-5 button-override"
            style="background: #fff"
            to="/redeem"
          >
            Redeem
          </v-btn>
        </div>
      </div>
    </div>
    <div class="section-header-large">
      <div class="container">
        <div class="w-layout-grid header-grid">
          <div id="w-node-_848dfd0d-3675-2138-3efe-4d3529f5c7fd-e987b744" class="header-block-left">
            <div class="header-title-container">
              <h1 class="hero-heading">One card.<br><span class="hero-heading-span">Many possibilities.</span></h1>
            </div>
<!--            <a href="#" class="link-arrow">learn more</a>-->
          </div>
          <div id="w-node-_03a2ec51-d7d6-42bd-1981-daf3863a07fe-e987b744" class="header-block">
            <img src="/images/star.svg" loading="lazy" alt="" class="star-image">
            <h1 class="hero-heading-h2">Goodcards are like wild cards, only better!</h1>
            <p class="hero-paragraph">
              Buy goodcards with any type of currency, fiat or crypto and redeem them whenever
              you want for a multitude of options.
            </p>
          </div>
        </div>
      </div>
      <div class="header-tall-image"></div>
    </div>
  </div>
</template>

<style lang="scss">
</style>

<script>
  export default {
    name: 'HomeView',
  };
</script>
