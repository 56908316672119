import Vue from 'vue';

Vue.filter('toCurrency', (value, currency = 'USD', minimumFractionDigits = 0) => {
  let val = value;
  if (typeof value === 'string') {
    val = parseFloat(value);
  }
  if (typeof val !== 'number') {
    return value;
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits,
  });
  return formatter.format(val);
});

Vue.filter('toCrypto', (_value, cryptoCode) => {
  const value = parseFloat(_value) || 0;

  const maxFractions = {
    BTC: 6,
    USDT: 3,
    ETH: 6,
  };
  const val = value.toLocaleString('en-US', {
    style: 'decimal',
    maximumFractionDigits: maxFractions[cryptoCode] || 6,
    // minimumFractionDigits: 2,
  });
  if (cryptoCode === 'BTC') {
    return `${val} BTC`;
  }
  if (cryptoCode === 'ETH') {
    return `Ξ ${val}`;
  }
  if (cryptoCode === 'USDT') {
    return `₮ ${val}`;
  }
  return val;
});

Vue.filter('toLocaleString', (value) => {
  if (!value) {
    return value;
  }
  return value.toLocaleString();
});

Vue.filter('toDate', (value) => {
  if (!value) {
    return value;
  }
  return (new Date(value)).toLocaleString();
});

Vue.filter('toDate', (value) => {
  if (!value) {
    return value;
  }
  return (new Date(value)).toLocaleString();
});
