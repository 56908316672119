<template>
  <div class="form-section">
    <h2 class="inner-heading">Redeem your <span class="span">goodcard</span></h2>
    <div class="form-content">
      <div class="breadcrumb">
        <div class="step-wrap completed">
          <div class="step-block completed">
            <div>1</div>
          </div>
          <div>Add Goodcard</div>
        </div>
        <div class="step-wrap completed">
          <div class="step-block completed">
            <div>2</div>
          </div>
          <div>Choose Options</div>
        </div>
        <div class="step-wrap active">
          <div class="step-block active">
            <div>3</div>
          </div>
          <div>Checkout</div>
        </div>
      </div>
      <div class="main-form-content centered">
        <img src="images/sad.svg" loading="lazy" alt="">
        <h2 class="form-heading centered">Bummer! The selected option is not currently available!</h2>
        <div class="warning-note">
          <img src="images/Question.svg" loading="lazy" alt="">
          <div class="warning-text">Please try again later or contact us at <a href="#" class="warning-text-link">lala@citadel.tools</a>.</div>
        </div>
      </div>
      <div class="button-wrap">
        <div class="left-buttons">
          <a href="/redeem/step3" class="back-button-slide w-button">Back</a>
          <a href="/" class="next-button-slide inactive w-button">Continue</a>
        </div>
        <a href="/" class="cancel-button w-button">Cancel</a>
      </div>
    </div>
  </div>
</template>

<style lang="css">

</style>
<script>
  import bus from '@/@libs/bus';
  import config from '../../../config';

  export default {
    name: 'RedeemFailView',
  };
</script>
