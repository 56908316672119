<template>
  <v-app  id="app" :class="className">
    <component :is="layout">
      <Confirm />
      <Alerts />
      <router-view/>
    </component>
  </v-app>
</template>

<style lang="scss">
  body {
    /*margin: 0;*/
    padding: 0;
  }
  $font-family:'Poppins';

  .v-application {
    [class*='text-'] {
      color: #36405a;
      font-family: $font-family, sans-serif !important;
    }
    font-family: $font-family, sans-serif !important;
  }
  .button-override {
    text-transform: none !important;
    letter-spacing: 0px !important;
    font-weight: 600 !important;
  }
</style>

<script>
  import bus from '@/@libs/bus';
  import './@layouts';
  import Alerts from '@/@components/Alerts';
  import Confirm from '@/@components/Confirm';

  export default {
    name: 'app',
    components: {
      Alerts,
      Confirm,
    },
    async created() {},
    mounted() {
      bus.on('error', this.handleError);
    },
    beforeDestroy() {
      bus.off('error', this.handleError);
    },
    computed: {
      layout() {
        // it seems the when matched array is empty it means that a route has not been matched yet
        // so we don't want to show the default-layout which might flash before the correct layout loads
        if (!this.$route.matched.length) {
          return 'empty-layout';
        }
        return this.$route.meta.layout || 'default-layout';
      },
      className() {
        return this.$route.meta.className || '';
      },
    },
    watch: {

    },
    methods: {
      async handleError({ error }) {
        // if (error.code === 'HTTP_NOT_AUTHORIZED') {
        //   await this.$router.replace({ name: 'noActiveSession' }).catch((err) => {
        //     console.error(err);
        //   }); // routing to same route throws error, catching to ignore that
        // }
      },
    },
  };
</script>
