<template>
  <div class="form-section">
    <h2 class="inner-heading">Redeem your <span class="span">goodcard</span></h2>
    <div class="form-content">
      <div class="breadcrumb">
        <div class="step-wrap completed">
          <div class="step-block completed">
            <div>1</div>
          </div>
          <div>Add Goodcard</div>
        </div>
        <div class="step-wrap active">
          <div class="step-block active">
            <div>2</div>
          </div>
          <div>Choose Options</div>
        </div>
        <div class="step-wrap">
          <div class="step-block">
            <div>3</div>
          </div>
          <div>Checkout</div>
        </div>
      </div>
      <div class="main-form-content flex">
        <div class="goodcard-container">
          <h3 class="form-heading-h3">Your goodcard</h3><img src="images/large-card.svg" loading="lazy" alt="">
          <p class="p-15">
            You are about to redeem your <strong>${{gcValue}}</strong> goodcard with number <br>
            <strong>{{gcCode}}</strong> and <br/>
            PIN: <strong>{{gcPIN}}</strong>
          </p>
        </div>
        <div class="goodcard-selector">
          <h3 class="form-heading-h3">You can redeem your goodcard with any of the options below:</h3>
          <div class="radio-buttons-container">
            <div
              v-for="option in availableOptions"
              class="radio-btn-selector"
              :key="option.name"
              @click="redeemOption = option"
            >
              <div class="radio-selector" :class="{active: option.type === redeemOption.type}" />
              <img :src="`images/${option.icon}`" loading="lazy" alt="" class="image">
              <div class="radio-selector-text-container">
                <p class="p-15 bold">{{option.name}}</p>
                <p class="p-13">{{ option.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="button-wrap">
        <div class="left-buttons">
          <v-btn
            text
            color="primary"
            class="pa-5 button-override"
            @click="back"
          >
            Back
          </v-btn>
          <v-btn
            depressed
            color="primary"
            class="pa-5 button-override"
            @click="setRedeemOption"
          >
            Continue
          </v-btn>
        </div>
        <v-btn
          text
          color="error"
          class="pa-5 button-override"
          @click="resetForm"
        >
          Cancel
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Step2',
    data() {
      return {
        redeemOption: this.$store.state.redeemWizard.details.step2.redeemOption,
      };
    },
    computed: {
      availableOptions() {
        return [
          {
            type: 'bitjem',
            name: 'Bitjem Gift Card',
            icon: 'small-card.svg',
            description: 'Use your Bitjem card in any online crypto store.',
          },
          {
            type: 'walmart',
            name: 'Walmart Gift Card',
            icon: '',
            description: 'User your Walmart card in store or at Walmart.com',
          },
        ];
      },
      gcValue() {
        return this.$store.getters['redeemWizard/giftCardValue'];
      },
      gcCode() {
        return this.$store.getters['redeemWizard/giftCardCode'];
      },
      gcPIN() {
        return this.$store.getters['redeemWizard/giftCardPIN'];
      },
    },
    methods: {
      back() {
        this.$store.dispatch('redeemWizard/back');
      },
      setRedeemOption() {
        this.$store.dispatch('redeemWizard/setRedeemOption', { redeemOption: this.redeemOption });
      },
      resetForm() {
        this.$store.dispatch('redeemWizard/reset');
      },
    },
  };
</script>

<style scoped>

</style>
