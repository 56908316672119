import isString from 'lodash/isString';
import assign from 'lodash/assign';
import isObject from 'lodash/isObject';
import omit from 'lodash/omit';
import qs from 'qs';
import pickBy from 'lodash/pickBy';
import isEqual from 'lodash/isEqual';

import {
  HttpNotFoundError,
  HttpNotAuthorizedError,
  HttpForbiddenError,
  HttpServerError,
  HttpBadRequestError,
} from '@/@constants/errors';
import config from '../../../config';
import auth from '../auth';
import bus from '../bus';

let pendingGETRequests = {};

const http = {
  getDiff(original, updated) {
    const updates = pickBy(updated, (value, field) => {
      return !isEqual(original[field], updated[field]);
    });
    return updates;
  },
  getAuthorizedUrl(optionsOrUrl) {
    let options = optionsOrUrl;
    if (isString(optionsOrUrl)) {
      options = {
        url: optionsOrUrl,
      };
    }
    let { url } = options;
    if (!url.match(/^https?:\/\//)) {
      url = `${config.apiPath.replace(/\/$/, '')}/${url.replace(/^\//, '')}`;
    }
    const query = options.query || options.qs || {};
    query.authorization = auth.getToken();
    const queryString = qs.stringify(query, { encode: false });
    if (queryString) {
      url += url.indexOf('?') === -1 ? '?' : '&';
      url += queryString;
    }
    return url;
  },
  async head(url, options) {
    return this.callRequest('HEAD', url, options);
  },
  async get(url, options) {
    return this.callRequest('GET', url, options);
  },

  async post(url, options) {
    return this.callRequest('POST', url, options);
  },

  async put(url, options) {
    return this.callRequest('PUT', url, options);
  },

  async delete(url, options) {
    return this.callRequest('DELETE', url, options);
  },

  async callRequest(method, url, options) {
    if (isString(url)) {
      return http.request({
        ...options,
        url,
        method,
      });
    }
    return http.request({
      ...url,
      method,
    });
  },

  async request(options) {
    let { url } = options;
    if (!url) {
      throw new Error('No URL was passed to http request.');
    }
    if (!url.match(/^https?:\/\//)) {
      url = `${config.apiPath.replace(/\/$/, '')}/${url.replace(/^\//, '')}`;
    }
    const query = options.query || options.qs;
    if (query && Object.keys(query).length) {
      const queryString = qs.stringify(query, { encode: true });
      if (queryString) {
        url += url.indexOf('?') === -1 ? '?' : '&';
        url += queryString;
      }
    }

    const headers = {
      authorization: `bearer ${auth.getToken() || ''}`,
      ...options.headers,
    };
    const method = (options.method || (options.body ? 'POST' : 'GET')).toUpperCase();

    let responsePromise;

    if (method === 'GET' && !options.noCache) {
      responsePromise = pendingGETRequests[url];
    }

    if (!responsePromise) {
      responsePromise = fetch(url, {
        method,
        headers: (() => {
          // unless the body is a string we assume that we are doing a JSON request
          if (options.multipart || (options.body && isString(options.body))) {
            return headers;
          }
          return assign({
            Accept: 'application/json',
            'Content-Type': 'application/json',
          }, headers);
        })(),
        body: (() => {
          if (options.body && isObject(options.body)) {
            if (options.multipart) {
              const formData = new FormData();
              Object.keys(options.body)
                .forEach((key) => {
                  formData.append(key, options.body[key]);
                });
              return formData;
            }
            return JSON.stringify(options.body);
          }
          return options.body;
        })(),
      })
        .then(async (response) => {
          let data = {};
          try {
            data = await response.json();
          } catch (e) {
            console.error(`Failed to parse JSON response for ${url}`);
          }
          // todo: not taking non json responses into consideration
          // eslint-disable-next-line no-param-reassign
          response.jsonData = data;
          return response;
        })
        .catch((e) => {
          e.message = `${e.message} - ${url}`;
          throw e;
        });
      if (method === 'GET') {
        pendingGETRequests[url] = responsePromise;
      }
    }

    let response;
    const start = Date.now();

    try {
      response = await responsePromise;
    } finally {
      if (method === 'GET' && pendingGETRequests[url]) {
        pendingGETRequests = omit(pendingGETRequests, [url]);
      }
    }
    const end = Date.now();

    const data = response.jsonData;

    bus.trigger('http-request-completed', { response, method, data, start, end });
    if (response.status >= 200 && response.status < 300) {
      // code to extract from header paxful rate remaining
      const paxfulRateRemaining = response.headers.get('paxful-rate-remaining');
      if (paxfulRateRemaining) {
        const [groupId, rate] = paxfulRateRemaining.split(' - ');
        bus.trigger('paxful-rate-remaining', { groupId, rate });
      }

      const authToken = response.headers.get('auth_token');
      if (authToken) {
        if (authToken === 'clear') {
          auth.removeToken();
        } else {
          auth.setToken(authToken);
        }
      }
      return data;
    }
    let error;
    if (response.status === 500) {
      error = new HttpServerError(/* response.statusText */);
    } else if (response.status === 400) {
      error = new HttpBadRequestError(/* response.statusText */);
    } else if (response.status === 401) {
      error = new HttpNotAuthorizedError(/* response.statusText */);
    } else if (response.status === 403) {
      error = new HttpForbiddenError(/* response.statusText */);
    } else if (response.status === 404) {
      error = new HttpNotFoundError(/* response.statusText */);
    } else {
      error = new Error(response.statusText);
    }
    error.data = data;
    error.statusCode = response.status;
    error.silent = response.status === 403 && options.ignoreForbidden;
    // error.data.errorMessage = `${error.data.errorMessage || ''} ${url}`;

    // this was changed to not throw error because it caused other code to fail
    if (response.status === 403 && options.ignoreForbidden) {
      return null;
    }

    throw error;
  },

  rpc(remoteMethod, data) {
    return http.request({
      url: `/api/${remoteMethod}`,
      method: 'POST',
      body: data,
    });
  },
};

export default http;
