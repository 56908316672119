import Vue from 'vue';
import Vuex from 'vuex';

import constants from './constants';
import redeemWizard from './redeemWizard';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    constants,
    redeemWizard,
  },
  state: {
    initialized: false,
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    async initialize({ state, dispatch }) {
      try {
        await Promise.all([
          dispatch('constants/fetchConstants'),
        ]);
      } finally {
        state.initialized = true;
      }
    },
  },
});
