<template>
  <div class="form-section">
    <h2 class="inner-heading">Redeem your <span class="span">goodcard</span></h2>
    <div class="form-content">
      <div class="breadcrumb">
        <div class="step-wrap completed">
          <div class="step-block completed">
            <div>1</div>
          </div>
          <div>Add Goodcard</div>
        </div>
        <div class="step-wrap active">
          <div class="step-block active">
            <div>2</div>
          </div>
          <div>Choose Options</div>
        </div>
        <div class="step-wrap">
          <div class="step-block">
            <div>3</div>
          </div>
          <div>Checkout</div>
        </div>
      </div>
      <div class="main-form-content flex">
        <div class="goodcard-container">
          <h3 class="form-heading-h3">Your goodcard</h3><img src="images/Card-example.svg" loading="lazy" alt="">
          <p class="p-15">You are about to redeem your <strong>$150</strong> goodcard with number <br><strong>1234 1234 1234 1234.</strong></p>
        </div>
        <div class="goodcard-selector">
          <h3 class="form-heading-h3">You can redeem your goodcard with any of the options below:</h3>
          <div class="radio-buttons-container">
            <div class="radio-btn-selector">
              <div class="radio-selector"></div><img src="images/card-150.svg" loading="lazy" alt="" class="image">
              <div class="radio-selector-text-container">
                <p class="p-15 bold">$150 Bitjem Card</p>
                <p class="p-13">Use your Bitjem card in any online crypto store.</p>
              </div>
            </div>
            <div class="radio-btn-selector active">
              <div class="radio-selector active"></div><img src="images/card-100.svg" loading="lazy" alt="" class="image">
              <div class="radio-selector-text-container">
                <p class="p-15 bold">$100 Bitjem Card</p>
                <p class="p-13">Use your Bitjem card in any online crypto store.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="button-wrap">
        <div class="left-buttons">
          <a href="/redeem/step1" class="back-button-slide w-button">Back</a>
          <a href="/redeem/step3" class="next-button-slide w-button">Continue</a>
        </div>
        <a href="/" class="cancel-button w-button">Cancel</a>
      </div>
    </div>
  </div>
</template>

<style lang="css">

</style>
<script>
  import bus from '@/@libs/bus';
  import config from '../../../config';

  export default {
    name: 'Redeem2View'
  };
</script>
