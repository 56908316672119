<template>
  <div id="errorAlertsContainer">
<!--    <v-alert
      v-if="message"
      :class="`alert-box alert-box-${type}`"
      border="top"
      :color="type"
      dark
    >
      {{message}}
    </v-alert>-->
    <div v-for="(alert, i) in alerts" :key="i">
      <v-alert
        v-model="alert.show"
        dismissible
        v-if="message"
        :class="`alert-box alert-box-${alert.type}`"
        dense
        border="left"
        :color="alert.type"
        dark
      >
        {{alert.message}}
      </v-alert>
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>

<script>
  import bus from '@/@libs/bus';

  export default {
    name: 'errorAlerts',
    data() {
      return {
        alerts: [],
      };
    },
    components: {
    },
    computed: {
      showSnackbar() {
        return this.alerts.length > 0;
      },
      message() {
        return this.alerts.length && this.alerts[this.alerts.length - 1].message;
      },
      isHtml() {
        return this.alerts.length && this.alerts[this.alerts.length - 1].isHtml;
      },
      type() {
        return this.alerts.length ? this.alerts[this.alerts.length - 1].type : 'info';
      },
    },
    methods: {
      handleAlert({ type = 'info', message, persist, isHtml = false }) {
        if (this.alerts.length > 4) {
          this.alerts.shift();
        }
        const id = Math.random();
        this.alerts.push({
          id,
          type,
          message,
          isHtml,
          show: true,
        });
        // if (type !== 'error' && persist !== true) {
        if (persist !== true) {
          setTimeout(() => {
            this.alerts = this.alerts.filter((alert) => alert.id !== id);
          }, type !== 'error' ? 3000 : 15000);
        }
      },
      handleError({ error }) {
        if (error.silent) {
          return;
        }
        let { message } = error;
        let isHtml = false;
        if (error.data && error.data.errorData && error.data.errorData.html) {
          message = error.data.errorData.html;
          isHtml = true;
        } else if (error.data && error.data.errorMessage) {
          message = error.data.errorMessage;
        }
        this.handleAlert({
          message,
          type: 'error',
          isHtml,
        });
      },
      close() {
        this.alerts.pop();
      },
    },
    mounted() {
      bus.on('error', this.handleError);
      bus.on('alert', this.handleAlert);
    },
    beforeDestroy() {
      bus.off('error', this.handleError);
      bus.off('alert', this.handleAlert);
    },
  };
</script>
