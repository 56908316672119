const constantsModule = {
  namespaced: true,
  state: {
    status: 'empty',
    examples: null,
  },
  // mutations, actions, getters...
  getters: {
    examplesItems({ examples }) {
      if (!examples) {
        return [];
      }
      return Object.keys(examples).map((key) => ({
        text: examples[key],
        value: key,
      }));
    },
  },
  mutations: {
    setConstants(state, constants) {
      Object.keys(constants).forEach((key) => {
        state[key] = constants[key];
      });
    },
  },
  actions: {
    async fetchConstants({ commit }) {
      if (this.status !== 'empty') {
        this.status = 'loading';
        // TODO: make endpoint and put uncomment code here
        // const constants = await http.get('/enums');
        const constants = {
          examples: [],
        };
        commit('setConstants', constants);
        this.status = 'loaded';
      }
    },
  },
};

export default constantsModule;
