import http from '@/@libs/http';
import config from '../../../config';

const getDefaultState = () => {
  return {
    currentStep: 1,
    giftCard: {},
    details: {
      step1: {
        code: null,
      },
      step2: {
        redeemOption: { type: 'bitjem' },
      },
      step3: {

      },
    },
  };
};

const redeemWizardModule = {
  namespaced: true,
  state: getDefaultState(),
  // mutations, actions, getters...
  getters: {
    giftCardValue(state) {
      return state.giftCard.value;
    },
    giftCardCode(state) {
      return state.giftCard.code;
    },
    giftCardPIN(state) {
      return state.giftCard.pin;
    },
  },
  mutations: {
    back(state) {
      if (state.currentStep !== 4) state.currentStep -= 1;
      else state.currentStep = 2;

      if (state.currentStep < 1) state.currentStep = 1;
    },
    reset(state) {
      Object.assign(state, getDefaultState());
    },
    setCode(state, { code, giftCard }) {
      state.details.step1.code = code;
      state.giftCard = giftCard;
      state.currentStep += 1;
    },
    setRedeemOption(state, { redeemOption }) {
      state.details.step2.redeemOption = redeemOption;
      state.currentStep += 1;
    },
    outOfStock(state) {
      state.currentStep = 4;
    },
  },
  actions: {
    back({ commit }) {
      commit('back');
    },
    reset({ commit }) {
      commit('reset');
    },
    async setCode({ commit }, code) {
      // todo validate code
      const giftCard = await http.post(`${config.apiPath}/v1/gift-cards/decode`, { body: {
        code,
      } });

      commit('setCode', { code, giftCard });
    },
    setRedeemOption({ commit }, { redeemOption }) {
      commit('setRedeemOption', { redeemOption });

      if (redeemOption.type !== 'bitjem') {
        commit('outOfStock');
      }
    },
  },
};

export default redeemWizardModule;
