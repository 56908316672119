<template>
  <div class="form-section">
    <h2 class="inner-heading">Redeem your <span class="span">goodcard</span></h2>
    <div class="form-content">
      <div class="breadcrumb">
        <div class="step-wrap completed">
          <div class="step-block completed">
            <div>1</div>
          </div>
          <div>Add Goodcard</div>
        </div>
        <div class="step-wrap completed">
          <div class="step-block completed">
            <div>2</div>
          </div>
          <div>Choose Options</div>
        </div>
        <div class="step-wrap active">
          <div class="step-block active">
            <div>3</div>
          </div>
          <div>Checkout</div>
        </div>
      </div>
      <div class="main-form-content centered">
        <img src="images/sad.svg" loading="lazy" alt="">
        <h2 class="form-heading centered">Bummer! The selected option is not currently available!</h2>
        <div class="warning-note">
          <img src="images/Question.svg" loading="lazy" alt="">
          <div class="warning-text">
            This card is currently out of stock, please try again later
          </div>
        </div>
      </div>
      <div class="button-wrap">
        <div class="left-buttons">
          <v-btn
            text
            color="primary"
            class="pa-5 button-override"
            @click="back"
          >
            Back
          </v-btn>
          <v-btn
            depressed
            color="primary"
            class="pa-5 button-override"
            disabled
          >
            Continue
          </v-btn>
        </div>
        <v-btn
          text
          color="error"
          class="pa-5 button-override"
          @click="resetForm"
        >
          Cancel
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Failed',
    methods: {
      back() {
        this.$store.dispatch('redeemWizard/back');
      },
      resetForm() {
        this.$store.dispatch('redeemWizard/reset');
      },
    },
  };
</script>

<style scoped>

</style>
