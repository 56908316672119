<template>
  <div class="form-section">
    <h2 class="inner-heading">Redeem your <span class="span">goodcard</span></h2>
    <div class="form-content">
      <div class="breadcrumb">
        <div class="step-wrap active">
          <div class="step-block active">
            <div>1</div>
          </div>
          <div>Add Goodcard</div>
        </div>
        <div class="step-wrap">
          <div class="step-block">
            <div>2</div>
          </div>
          <div>Choose Options</div>
        </div>
        <div class="step-wrap">
          <div class="step-block">
            <div>3</div>
          </div>
          <div>Checkout</div>
        </div>
      </div>
      <div class="main-form-content">
        <h2 class="form-heading">Type below the code of your goodcard.</h2>
        <div class="form-notification">
          <div>This is the code you should have received from a partering site.</div>
          <img src="images/card-no.svg" loading="lazy" alt="">
        </div>
        <div class="form-block w-form">
          <form id="email-form" name="email-form" data-name="Email Form" @submit.prevent="setCode">
            <label for="name" class="form-label">code</label>
            <input
              type="text"
              class="form-text-field w-input"
              maxlength="256"
              name="name"
              data-name="Name"
              placeholder=""
              id="name"
              v-model="code"
              @
            >
            <div class="form-letter-count"></div>
          </form>
          <div class="w-form-done">
            <div>Thank you! Your submission has been received!</div>
          </div>
          <div class="w-form-fail">
            <div>Oops! Something went wrong while submitting the form.</div>
          </div>
        </div>
      </div>
      <div class="button-wrap">
        <div class="left-buttons">
          <v-btn
            text
            color="primary"
            class="pa-5 button-override"
            disabled
          >
            Back
          </v-btn>
          <v-btn
            depressed
            color="primary"
            class="pa-5 button-override"
            @click="setCode"
            :disabled="disableContinueBtn"
          >
            Continue
          </v-btn>
        </div>
        <v-btn
          text
          color="error"
          class="pa-5 button-override"
          disabled
        >
          Cancel
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Step1',
    data() {
      return {
        code: this.$store.state.redeemWizard.details.step1.code || '',
      };
    },
    computed: {
      disableContinueBtn() {
        return this.code === undefined || this.code.length < 12;
      },
    },
    methods: {
      setCode() {
        if (this.disableContinueBtn) return;
        this.$store.dispatch('redeemWizard/setCode', this.code);
      },

    },
  };
</script>

<style scoped>

</style>
