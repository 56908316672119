<template>
  <div class="form-section">
    <h2 class="inner-heading">Redeem your <span class="span">goodcard</span></h2>
    <div class="form-content">
      <div class="breadcrumb">
        <div class="step-wrap completed">
          <div class="step-block completed">
            <div>1</div>
          </div>
          <div>Add Goodcard</div>
        </div>
        <div class="step-wrap completed">
          <div class="step-block completed">
            <div>2</div>
          </div>
          <div>Choose Options</div>
        </div>
        <div class="step-wrap active">
          <div class="step-block active">
            <div>3</div>
          </div>
          <div>Checkout</div>
        </div>
      </div>
      <div class="main-form-content centered">
        <img src="images/smile.svg" loading="lazy" alt="">
        <h2 class="form-heading centered">Thank you!<br>Details of your transaction below</h2>
        <div class="form-details-container">
          <div class="card-to-redeem">
            <p class="p-14">You redeemed your goodcard with serial: <strong>1234 1234 1234 1234</strong></p><img src="images/Card-example.svg" loading="lazy" alt="">
          </div>
          <div class="details-bitjem-card">
            <p class="p-14">Your Bitjem card</p><img src="images/card-100.svg" loading="lazy" alt="" class="bitjem-selected-details">
            <div class="serial-code-container">
              <p class="p-14">Copy your serial code to use:</p>
              <div class="serial-number-txt">1234 1234 1234 1234 1234</div>
            </div>
          </div>
        </div>
      </div>
      <div class="button-wrap">
        <div class="left-buttons">
          <a href="/redeem/step2" class="back-button-slide w-button">Back</a>
          <a href="/redeem/fail" class="next-button-slide w-button">Continue</a>
        </div>
        <a href="/" class="cancel-button w-button">Cancel</a>
      </div>
    </div>
  </div>
</template>

<style lang="css">

</style>
<script>
  import bus from '@/@libs/bus';
  import config from '../../../config';

  export default {
    name: 'Redeem2View'
  };
</script>
