<template>
  <div class="loading-text">Loading, please wait ...</div>
</template>

<style lang="scss" scoped>
  .loading-text {
    font-size: 20px;
    margin: 20px;
  }
</style>

<script>
  export default {
    name: 'Loading',
    components: {
    },
    props: {},
    data() {
      return {
      };
    },
    created() {
    },
    computed: {
    },
    methods: {
    },
  };
</script>
