<template>
  <v-main>
    <div class="application">
      <div class="empty-layout" v-if="initialized">
        <slot />
      </div>
      <div v-else>
        <c-loading />
      </div>
    </div>
  </v-main>
</template>

<style lang="scss">
</style>

<script>
  export default {
    name: 'EmptyLayout',
    components: {
    },
    props: {},
    data() {
      return {
      };
    },
    created() {
    },
    computed: {
      initialized() {
        return this.$store.state.initialized;
      },
    },
    methods: {
    },
  };
</script>
