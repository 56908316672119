<template>
  <div class="form-section">
    <h2 class="inner-heading">Redeem your <span class="span">goodcard</span></h2>
    <div class="form-content">
      <div class="breadcrumb">
        <div class="step-wrap completed">
          <div class="step-block completed">
            <div>1</div>
          </div>
          <div>Add Goodcard</div>
        </div>
        <div class="step-wrap completed">
          <div class="step-block completed">
            <div>2</div>
          </div>
          <div>Choose Options</div>
        </div>
        <div class="step-wrap active">
          <div class="step-block active">
            <div>3</div>
          </div>
          <div>Checkout</div>
        </div>
      </div>
      <div class="main-form-content centered">
        <img src="images/smile.svg" loading="lazy" alt="">
        <h2 class="form-heading centered">Thank you!<br>Details of your transaction below</h2>
        <div class="form-details-container">
          <div class="card-to-redeem">
            <p class="p-14">
              You redeemed your <strong>${{gcValue}}</strong> goodcard</p>
            <img src="images/large-card.svg" loading="lazy" alt="">
          </div>
          <div class="details-bitjem-card">
            <p class="p-14">Your Bitjem card</p>
            <img src="images/small-card.svg" loading="lazy" alt="" class="bitjem-selected-details">
            <div class="serial-code-container">
              <p class="p-14">Copy your serial code to use:</p>
              <div class="serial-number-txt">{{gcCode}}</div>
              <br/>
              <p class="p-14">Copy your PIN to use:</p>
              <div class="serial-number-txt">{{gcPIN}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="button-wrap">
        <div class="left-buttons">
          <v-btn
            text
            color="primary"
            class="pa-5 button-override"
            @click="back"
          >
            Back
          </v-btn>
          <v-btn
            depressed
            color="primary"
            class="pa-5 button-override"
            @click="resetForm"
          >
            Redeem Another Card
          </v-btn>
        </div>
        <v-btn
          text
          color="error"
          class="pa-5 button-override"
          disabled
        >
          Cancel
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Step3',
    computed: {
      gcValue() {
        return this.$store.getters['redeemWizard/giftCardValue'];
      },
      gcCode() {
        return this.$store.getters['redeemWizard/giftCardCode'];
      },
      gcPIN() {
        return this.$store.getters['redeemWizard/giftCardPIN'];
      },
    },
    methods: {
      back() {
        this.$store.dispatch('redeemWizard/back');
      },
      resetForm() {
        this.$store.dispatch('redeemWizard/reset');
      },
    },
  };
</script>

<style scoped>

</style>
