import { render, staticRenderFns } from "./Step3.vue?vue&type=template&id=52b31216&scoped=true&"
import script from "./Step3.vue?vue&type=script&lang=js&"
export * from "./Step3.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52b31216",
  null
  
)

export default component.exports