<template>
  <div>
    <Step1 v-if="currentStep===1"/>
    <Step2 v-if="currentStep===2"/>
    <Step3 v-if="currentStep===3"/>
    <Failed v-if="currentStep===4"/>
  </div>
</template>

<style lang="scss">
</style>

<script>
  import Step1 from '@/App/Redeem/@components/Step1';
  import Step2 from '@/App/Redeem/@components/Step2';
  import Step3 from '@/App/Redeem/@components/Step3';
  import Failed from '@/App/Redeem/@components/Failed';

  export default {
    name: 'Redeem',
    components: {
      Step3,
      Step2,
      Step1,
      Failed,
    },
    props: {},
    data() {
      return {
      };
    },
    created() {
    },
    computed: {
      currentStep() {
        return this.$store.state.redeemWizard.currentStep;
      },
    },
    methods: {
    },
  };
</script>
