<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    ...
  </div>
</template>

<script>
  export default {
    name: 'Loader',
  };
</script>
