<template>
  <v-main>
    <div class="application">
      <div class="default-layout pt-2" v-if="initialized">

        <div>
          <div data-collapse="medium" data-animation="default" data-duration="400" data-easing="ease" data-easing2="ease" data-doc-height="1" role="banner" class="navigation inner w-nav">
            <div class="navigation-container">
              <router-link to="/" class="logo-link w-inline-block">
                <img src="/images/goodcards-logo-h.svg" loading="lazy" width="124" alt="" class="logo">
              </router-link>
              <div class="navigation-wrapper">
<!--                <div class="menu-button w-nav-button">
                  <div class="menu-icon w-embed">
                    <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5.125 5.88892H18.875V7.41669H5.125V5.88892ZM5.125 11.2361H18.875V12.7639H5.125V11.2361ZM5.125 16.5834H18.875V18.1111H5.125V16.5834Z" fill="currentColor"></path>
                    </svg>
                  </div>
                </div>-->
<!--                <nav role="navigation" class="navigation-menu w-nav-menu">
                  <a href="#" class="navigation-link inner w-nav-link">About GoodCards</a>
                  <a href="#" class="navigation-link inner w-nav-link">Buy</a>
                </nav>-->
<!--                <a href="redeem/step1" aria-current="page" class="navigation-button inner w-inline-block w&#45;&#45;current">
                  <div>Redeem</div>
                </a>-->
                <v-btn
                  depressed
                  color="primary"
                  class="pa-5 button-override"
                  to="/redeem"
                >
                  Redeem
                </v-btn>
              </div>
            </div>
          </div>
          <div class="main-container">
            <slot />
          </div>
        </div>
      </div>
      <div v-else>
        <c-loading />
      </div>
    </div>
  </v-main>
</template>

<style lang="scss">
  .application {
    max-width: 1920px;
    margin: 0 auto;
  }
</style>

<script>
  export default {
    name: 'DefaultLayout',
    components: {
    },
    props: {},
    data() {
      return {
      };
    },
    created() {
    },
    computed: {
      initialized() {
        return this.$store.state.initialized;
      },
    },
    methods: {
    },
  };
</script>
