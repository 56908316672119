import Vue from 'vue';
import VueRouter from 'vue-router';

import config from '../config';

import auth from './@libs/auth';

import HomeView from './App/Home';
import NotFound from './App/NotFound';
import Redeem from './App/Redeem';

import store from './@state';
import Redeem1View from './App/Redeem-1/Redeem1.vue';
import Redeem2View from './App/Redeem-2/Redeem2.vue';
import Redeem3View from './App/Redeem-3/Redeem3.vue';
import RedeemFailView from './App/Redeem-Fail/Redeem4.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      layout: 'empty-layout',
      public: true,
    },
  },
  {
    path: '/redeem',
    name: 'redeem',
    component: Redeem,
    meta: {
      public: true,
    },
  },
  {
    path: '/redeem/step1',
    name: 'redeem1',
    component: Redeem1View,
    meta: {
      public: true,
    },
  },
  {
    path: '/redeem/step2',
    name: 'redeem2',
    component: Redeem2View,
    meta: {
      public: true,
    },
  },
  {
    path: '/redeem/step3',
    name: 'redeem3',
    component: Redeem3View,
    meta: {
      public: true,
    },
  },
  {
    path: '/redeem/fail',
    name: 'fail',
    component: RedeemFailView,
    meta: {
      public: true,
    },
  },
  {
    path: '*',
    name: 'notFound',
    component: NotFound,
    meta: {
      public: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  // WARNING: https://github.com/vuejs/vue-router/issues/2873
  // WARNING: read above when dynamically routing such as this.$router.replace

  if (!store.state.initialized) {
    await store.dispatch('initialize');
  }

  if (!to.meta.public && !auth.getToken()) {
    next({ name: 'login' });
  } else {
    next();
  }
});

export default router;
