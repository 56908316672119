<template>
  <div class="form-section">
    <h2 class="inner-heading">Redeem your <span class="span">goodcard</span></h2>
    <div class="form-content">
      <div class="breadcrumb">
        <div class="step-wrap active">
          <div class="step-block active">
            <div>1</div>
          </div>
          <div>Add Goodcard</div>
        </div>
        <div class="step-wrap">
          <div class="step-block">
            <div>2</div>
          </div>
          <div>Choose Options</div>
        </div>
        <div class="step-wrap">
          <div class="step-block">
            <div>3</div>
          </div>
          <div>Checkout</div>
        </div>
      </div>
      <div class="main-form-content">
        <h2 class="form-heading">Type below the code of your goodcard.</h2>
        <div class="form-notification">
          <div>You can find the code in the email we sent you, next to the thingy that has some thingies.</div><img src="images/card-no.svg" loading="lazy" alt="">
        </div>
        <div class="form-block w-form">
          <form id="email-form" name="email-form" data-name="Email Form" method="get">
            <label for="name" class="form-label">code</label><input type="text" class="form-text-field w-input" maxlength="256" name="name" data-name="Name" placeholder="" id="name">
            <div class="form-letter-count"></div>
          </form>
          <div class="w-form-done">
            <div>Thank you! Your submission has been received!</div>
          </div>
          <div class="w-form-fail">
            <div>Oops! Something went wrong while submitting the form.</div>
          </div>
        </div>
      </div>
      <div class="button-wrap">
        <div class="left-buttons">
          <a href="/" class="back-button-slide w-button">Back</a>
          <a href="/redeem/step2" class="next-button-slide w-button">Continue</a>
        </div>
        <a href="/" class="cancel-button w-button">Cancel</a>
      </div>
    </div>
  </div>
</template>

<style lang="css">

</style>
<script>
  import bus from '@/@libs/bus';
  import config from '../../../config';

  export default {
    name: 'Redeem1View'
  };
</script>
